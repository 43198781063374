import React from 'react'
import { Layout, Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'
import ContactInfo from '@widgets/ContactInfo'
import Commitment from '@widgets/Commitment'

const PageContact = props => (
  <Layout {...props}>
    <Seo
      title='Everything Green | Advertise Your Cannabis Clinic'
      description='Advertise on our cannabis clinic directory, e-mail our double opted in subscribers or run a display campaign. Let us build your perfect advertising campaign.'
    />
    <Divider />
    <Stack>
      <Main>
        <PageTitle header='Legal Cannabis Advertising' />
        <p>
          Everything Green has a vast array of advertising options depending on
          your requirements. Advertise in our directory for free and get quality
          appointments from day one. E-mail our double verified subscribers in a
          solos e-mail or as part of our regular digital communications. Grow
          your brand, web traffic and sales through our sophisticated and highly
          targeted banner offering.
        </p>

        <p>
          With all advertising, Everything Green provides robust reports with
          in-depth data on results and commentary into overall performance.
        </p>

        <p>
          Let us know your needs and someone in our business development team
          will contact you to discuss requirements and build you a media plan.
        </p>
        <ContactForm />
        <Divider />
      </Main>
      <Sidebar>
        <Commitment />
        <Divider />
        <ContactInfo />
      </Sidebar>
    </Stack>
  </Layout>
)

export default PageContact
